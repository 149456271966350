<template>
  <div class="record-display">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>租借记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-search">
      <div class="left">
        <el-input placeholder="请输入租借人姓名" v-model="query.name" clearable @clear="getListData"></el-input>
        <el-button type="primary" @click="getListData">搜索</el-button>
      </div>
      <div class="right">
        <a href="https://api.easymedicinesr.com/admin/export-record-list" download="true">下载Excel表</a>
      </div>
    </div>
    <div class="page-content">
      <el-row>
        <el-table :data="dataList" border style="width: 100%">
          <el-table-column prop="user_name" label="租借人" width="180"></el-table-column>
          <el-table-column prop="order_status" label="状态" width="180" sortable>
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.order_status === 1">待审核</el-tag>
              <el-tag type="warning" v-else-if="scope.row.order_status === 2">租借中</el-tag>
              <el-tag type="success" v-else-if="scope.row.order_status === 3">已完成</el-tag>
              <el-tag type="info" v-else-if="scope.row.order_status === 4">已拒绝</el-tag>
              <el-tag type="danger" v-else-if="scope.row.order_status === 5">申请归还待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="device_title" label="租借设备"></el-table-column>
          <el-table-column prop="device_title" label="申请时间">
            <template slot-scope="scope">
              {{ moment(scope.row.creat_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-view" @click="viewItem(scope.row)" size="small"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="table-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-sizes="[30, 100, 200, 400]" :page-size="query.size" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </el-row>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="68%" :before-close="handleClose">
      <el-descriptions title="用户信息">
        <el-descriptions-item label="当前订单状态">
          <el-tag type="primary" v-if="orderInfo.order_status === 1">待审核</el-tag>
          <el-tag type="warning" v-else-if="orderInfo.order_status === 2">租借中</el-tag>
          <el-tag type="success" v-else-if="orderInfo.order_status === 3">已完成</el-tag>
          <el-tag type="info" v-else-if="orderInfo.order_status === 4">已拒绝</el-tag>
          <el-tag type="danger" v-else-if="orderInfo.order_status === 5">申请归还待审核</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="订单号">{{ orderInfo.order_id }}</el-descriptions-item>
        <el-descriptions-item label="租借人">{{ orderInfo.user_name }}</el-descriptions-item>
        <el-descriptions-item label="租借设备名">{{ orderInfo.device_title }}</el-descriptions-item>
        <el-descriptions-item label="开始借用时间" v-if="orderInfo.start_time !== 0 && orderInfo.start_time">{{ moment(orderInfo.start_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-descriptions-item>
        <el-descriptions-item label="开始借用时间" v-else>无</el-descriptions-item>
        <el-descriptions-item label="归还时间" v-if="orderInfo.end_time !== 0 && orderInfo.end_time">{{ moment(orderInfo.end_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-descriptions-item>
        <el-descriptions-item label="归还时间" v-else>无</el-descriptions-item>
        <el-descriptions-item label="拒绝申请时间" v-if="orderInfo.refuse_time !== 0 && orderInfo.refuse_time">{{ moment(orderInfo.refuse_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-descriptions-item>
        <el-descriptions-item label="拒绝申请时间" v-else>无</el-descriptions-item>
        <el-descriptions-item label="处理护士">{{ orderInfo.deal_nurse_name }}</el-descriptions-item>
        <el-descriptions-item label="确认归还护士">{{ orderInfo.confirm_nurse_name }}</el-descriptions-item>
        <el-descriptions-item label="用户申请时间">{{ moment(orderInfo.creat_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-descriptions-item>
        <el-descriptions-item label="订单归属医院">{{ orderInfo.hospital_title }}</el-descriptions-item>
        <el-descriptions-item label="订单归属病区">{{ orderInfo.area_title }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'RecordDisplay',
  data () {
    return {
      query: {
        name: '',
        size: 30,
        current: 1,
        total: 0,
        status: ''
      },
      orderStatus: [
        { value: 'pending', label: '待审核' },
        { value: 'leaseing', label: '租借中' },
        { value: 'revert', label: '待归还' },
        { value: 'rejected', label: '已拒绝' }
      ],
      dataList: [],
      dialogVisible: false,
      orderInfo: {
        order_status: 0,
        order_id: '',
        user_name: '',
        device_title: '',
        start_time: 0,
        end_time: 0,
        refuse_time: 0,
        deal_nurse_name: '',
        confirm_nurse_name: '',
        creat_time: 0,
        hospital_title: '',
        area_title: ''
      }
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    async getListData () {
      const { data: res } = await this.$http.get('/record-list', { params: this.query })
      if (res.meta.status === 200) {
        this.dataList = res.data.list
        this.query.current = res.data.current
        this.query.total = res.data.total
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleSizeChange (val) {
      this.query.size = val
    },
    handleCurrentChange (val) {
      this.query.current = val
    },
    editItem (id) {
      this.$router.push('/dashboard/record-edit/' + id)
    },
    viewItem (item) {
      this.orderInfo = item
      this.dialogVisible = true
    },
    handleClose (done) {
      done()
    }
  }
}
</script>

<style scoped>
.table-pagination{
  padding: 20px 0;
  text-align: center;
}
.page-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.page-search .left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-search .left .el-button{
  margin-left: 14px;
}
.page-search .right{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-search .right .title{
  margin-right: 14px;
  font-size: 14px;
}
</style>
